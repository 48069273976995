<template>
  <!-- 砍价明细 -->
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="goodsOptions" :rules="rules" label-width="130px">
      <topOperatingButton
        :showAudit="isShowAudit"
        :isStopBtn="false"
        :isSubmitAddBtn="false"
        :isExamineBtn="true"
        :disabled='disableOperate'
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="getQuit"
      >
      </topOperatingButton>

      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class="x-w marT10">
            <el-form-item label="活动编号" prop="billNo">
              <el-input
                class="inputWidth"
                :disabled="true"
                v-model="goodsOptions.billNo"
                placeholder="活动编号"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动名称" prop="billName">
              <el-input
                class="inputWidth"
                :disabled="disableOperate"
                v-model="goodsOptions.billName"
                placeholder="活动名称"
                maxlength="40"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动日期" prop="datetime">
              <el-date-picker
                v-model="goodsOptions.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="disableOperate"
                :picker-options="forbiddenTime"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案说明" prop="billRemark">
              <el-input
                size="mini"
                v-model="goodsOptions.billRemark"
                type="textarea"
                placeholder="方案说明长度介于 1 和 200 字符之间"
                maxlength="200"
                :autosize="{ minRows: 2, maxRows: 2 }"
                :disabled="disableOperate"
              />
            </el-form-item>
          </div>
          <div class="x-f ">
            <el-form-item label="支付方式" prop="type">
              <el-checkbox-group :disabled='disableOperate' v-model="goodsOptions.type">
                <el-checkbox label="微信支付" name="type"></el-checkbox>
                <el-checkbox label="会员卡支付" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="x-f ">
            <el-form-item label="配送方式" prop="delivery">
              <el-checkbox-group :disabled='disableOperate' v-model="goodsOptions.delivery">
                <el-checkbox label="门店自提" name="type"></el-checkbox>
                <el-checkbox label="同城配送" name="type"></el-checkbox>
                <el-checkbox label="快递配送" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom
        cardTitle="活动商品"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class=" marT10 mar20">
            <div class="mt20">
              <el-button :disabled='disableOperate' size="mini" @click="handleEvent('openGoodsDialog')">选择商品</el-button>
            </div>
            <EditTable style="width: 100%;" :options="goodsOptions" />
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="更多配置">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <el-form-item label="适用门店" :required="true">
                <el-radio :disabled='disableOperate' v-model="goodsOptions.shopRangeType"  :label="0">全部门店</el-radio>
                <el-radio :disabled='disableOperate' v-model="goodsOptions.shopRangeType"  :label="1">全部门店+排除</el-radio>
                <el-radio :disabled='disableOperate' v-model="goodsOptions.shopRangeType"  :label="2">指定门店</el-radio>
                <div v-if="[1, 2].includes(goodsOptions.shopRangeType)">
                  <el-button size="mini" :disabled='disableOperate' @click="handleEvent('openShopDialog')">选择门店</el-button>
                  <div class="mt10">
                    <EditTable :options="shopOptions" />
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="首刀砍价比例(%)" prop="bargainFirstRate" style="margin-bottom: 20px">
                <el-input style="width: 200px;"
                  :disabled='disableOperate'
                  oninput="if (value > 90) value = 90; value=value.replace(/^0|[^0-9]/g,'')"
                  class="fillRuleinputMoney" v-model="goodsOptions.bargainFirstRate"
                  :max="90"></el-input>
              </el-form-item>
              <div class="text" style="margin-bottom: 15px">此处为买家发起砍价时自己首刀砍价比例,建议设的更高一点提高买家砍价兴趣例: 商品售价为￥100.首刀比例为90%.则发起砍价时买家可以自己砍￥90</div>
              <el-form-item label="砍价有效期" prop="bargainLimitHour">
                <el-input style="width: 200px;" class="fillRuleinputMoney" 
                  :disabled='disableOperate'
                  @input="limitInputlength(goodsOptions.bargainLimitHour, goodsOptions,'bargainLimitHour', true, 0, false,9999)" size="mini"
                  v-model="goodsOptions.bargainLimitHour">
                  <template slot="append">
                    <el-button>小时</el-button>
                  </template>
                </el-input>
                <el-tooltip style="color: black;margin-left: 10px;" content="用户发起砍价到砍价截止的时间" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="砍价人数" prop="bargainPeopleNum">
                <el-input style="width: 200px;"
                  :disabled='disableOperate'
                  oninput="if (value > 10) value = 10; value=value.replace(/^0|[^0-9]/g,'')"
                  class="fillRuleinputMoney" v-model="goodsOptions.bargainPeopleNum"
                  ></el-input>
              </el-form-item>
              <el-form-item label="每人帮砍金额" prop="bargainNoPeopleType">
                <el-radio :disabled='disableOperate' v-model="goodsOptions.bargainNoPeopleType"  :label="1">随机</el-radio>
                <el-radio :disabled='disableOperate' v-model="goodsOptions.bargainNoPeopleType" :label="2">固定</el-radio>
              </el-form-item>
              <el-form-item label="购买设置" prop="bargainRuleType">
                <el-radio :disabled='disableOperate' v-model="goodsOptions.bargainRuleType"  :label="1">砍到任意金额购买</el-radio>
                <el-radio :disabled='disableOperate' v-model="goodsOptions.bargainRuleType" :label="2">砍到底价购买</el-radio>
              </el-form-item>
              <el-form-item label="发起砍价次数" prop="bargainLimitTimes">
                <el-input style="width: 200px;"
                  :disabled='disableOperate'
                  @input="limitInputlength(goodsOptions.bargainLimitTimes, goodsOptions,'bargainLimitTimes', true, 2, false,9999)" size="mini"
                  class="fillRuleinputMoney" v-model="goodsOptions.bargainLimitTimes"></el-input>
                <el-tooltip style="color: black;margin-left: 10px;" content="每个会员账号参与该活动能发起的砍价次数" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="帮砍次数" prop="bargainDayHelpTimes">
                <el-input style="width: 200px;"
                  :disabled='disableOperate'
                  @input="limitInputlength(goodsOptions.bargainDayHelpTimes, goodsOptions,'bargainDayHelpTimes', true, 2, false,9999)" size="mini"
                  class="fillRuleinputMoney" v-model="goodsOptions.bargainDayHelpTimes"></el-input>
                <el-tooltip style="color: black;margin-left: 10px;" content="限制每个会员每天能参与该活动的帮忙砍价次数" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </el-form>
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, fcount } from '@/utils'
import { getBillNo } from '@/api/codeRule'

import {
  bargainCaseSave,
  bargainCaseDetail,
  bargainCaseUpdate,
  bargainCaseAudit,
} from '@/api/O2OMall/marketing/bargain'

export default {
  name: 'BargainDetail',
  components: {
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
  },
  watch: {
    'goodsOptions.billStatus': {
      handler (val) {
          // 判断val是否在禁用操作的数组中，并设置disableOperate状态
          this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
          this.isShowAudit = !['0', 0].includes(val)
          this.goodsOptions.columns[5].disabled = this.disableOperate
          this.goodsOptions.columns[6].disabled = this.disableOperate
          this.goodsOptions.status = this.disableOperate
      },
      immediate: true
    },
  },
  data () {
    return {
    forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loading: false,
      clickItem: 0, //当前焦点下标
      disableOperate: false,
      isShowAudit: false,
      //弹窗配置
      dialogOptions: {
        curType: '', //当前类型(门店/会员/优惠券)
        title: '选择会员',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
        datetime: [
          { type: 'array', required: true, message: '请选择活动日期', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个支付方式', trigger: 'change' }
        ],
        delivery: [
          { type: 'array', required: true, message: '请至少选择一个配送方式', trigger: 'change' }
        ],
        billName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        bargainFirstRate: [
          { required: true, message: '请输入首刀砍价比例', trigger: 'blur' }
        ],
        bargainLimitHour: [
          { required: true, message: '请输入砍价有效期', trigger: 'blur' }
        ],
        bargainPeopleNum: [
          { required: true, message: '请输入砍价人数', trigger: 'blur' }
        ],
        bargainNoPeopleType: [
          { required: true, message: '请选择每人帮砍金额', trigger: ['blur', 'change'] }
        ],
        bargainRuleType: [
          { required: true, message: '请选择购买设置', trigger: ['blur', 'change'] }
        ],
        bargainLimitTimes: [
          { required: true, message: '请输入发起砍价次数', trigger: 'blur' }
        ],
        bargainDayHelpTimes: [
          { required: true, message: '请输入帮砍次数', trigger: 'blur' }
        ],
      },
      goodsOptions: {
        billNo: undefined,
        billId: undefined,
        billName: undefined,
        datetime: undefined,
        billRemark: undefined,
        type: [
          '微信支付', '会员卡支付'
        ],
        delivery: [
          '门店自提', '同城配送', '快递配送'
        ],
        shopRangeType: 0,
        isCanWeixinPay: undefined,
        isCanVipPay: undefined,
        isCanDistill: undefined,
        isCanDelivery: undefined,
        isCanExpress: undefined,
        bargainFirstRate: undefined,
        bargainLimitHour: undefined,
        bargainPeopleNum: undefined,
        bargainNoPeopleType: 1,
        bargainRuleType: 1,
        bargainLimitTimes: undefined,
        bargainDayHelpTimes: undefined,
        o2omallMarketingBargainCaseGoodsAddReqList: [],
        o2omallMarketingBargainCaseShopList: [],

        billStatus: '0',
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined,
        isNoAddBtn: true,
        isNoDelBtn: false,
        hideTotal: true,
        columns: [
          {
            prop: 'goodsNo',
            label: '商品编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'goodsSpec',
            label: '商品规格',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'unitName',
            label: '商品单位',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'salePrice',
            label: '售价',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'limitMinQty',
            label: '数量',
            width: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            rules: true,
            isMinus: true,
            maxNum: 99999999
          },
          {
            prop: 'limitMinPrice',
            label: '砍价底价',
            width: 120,
            align: 'center',
            type: 'input',
            input: 'limitMinPrice',
            disabled: false,
            rules: true,
            isMinus: true,
            maxNum: 99999999
          },
        ]
      },
      //门店表格配置
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'shopNo',
            label: '门店编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'shopName',
            label: '门店名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'shopTypeName',
            label: '门店类型',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: '',
            label: '',
            minWidth: 150,
            align: 'center'
          }
        ]
      },
    }
  },
  methods: {
    //退出按钮
    getQuit() {
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/bargain" });
    },
    async getDetail () {
      this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await bargainCaseDetail({billId: this.$route.query.billId})
          //方案赋值
          this.goodsOptions = {
            ...this.goodsOptions,
            ...data,
            list: [],
            datetime: [data.begTime, data.endTime],
          }
          this.goodsOptions.type = []
          this.goodsOptions.delivery = []
          this.goodsOptions.list = data.o2omallMarketingBargainCaseGoodsList? data.o2omallMarketingBargainCaseGoodsList : []
          if (this.goodsOptions.isCanWeixinPay) { this.goodsOptions.type.push('微信支付') }
          if (this.goodsOptions.isCanVipPay) { this.goodsOptions.type.push('会员卡支付') }
          if (this.goodsOptions.isCanDistill) { this.goodsOptions.delivery.push('门店自提') }
          if (this.goodsOptions.isCanDelivery) { this.goodsOptions.delivery.push('同城配送') }
          if (this.goodsOptions.isCanExpress) { this.goodsOptions.delivery.push('快递配送') }

          if ([1, 2].includes(this.goodsOptions.shopRangeType)) {
            this.shopOptions.list = this.goodsOptions.o2omallMarketingBargainCaseShopList.map((item) => (
                {
                    ...item,
                    shopTypeName: item.shopType == 0? '直营' : '加盟'
                }
            ))
          }
        } catch (err) {}
        this.loading = false
      } else {
        // this.reset()
        this.getBillno()
      }
    },
    async getBillno () {
      this.goodsOptions.billNo = await getBillNo(180203)
    },
    reset() {
      //表格配置
      this.goodsOptions = {
        ...this.goodsOptions,
        billNo: undefined,
        billId: undefined,
        billName: undefined,
        datetime: undefined,
        billRemark: undefined,
        type: [
          '微信支付', '会员卡支付'
        ],
        delivery: [
          '门店自提', '同城配送'
        ],
        shopRangeType: 0,
        isCanWeixinPay: undefined,
        isCanVipPay: undefined,
        isCanDistill: undefined,
        isCanDelivery: undefined,
        isCanExpress: undefined,
        bargainFirstRate: undefined,
        bargainLimitHour: undefined,
        bargainPeopleNum: undefined,
        bargainNoPeopleType: 1,
        bargainRuleType: 1,
        bargainLimitTimes: undefined,
        bargainDayHelpTimes: undefined,
        o2omallMarketingBargainCaseGoodsAddReqList: [],
        o2omallMarketingBargainCaseShopList: [],
        billStatus: '',
      }
      this.goodsOptions.list = [],
      this.shopOptions.list = [],
      this.resetForm('form')
    },
    limitInputlength,
    async handleEvent (type, row) {
      switch (type) {
        // case 'nowWriteOffMoney':
        // 
        // break
        case 'openGoodsDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'goodsOptions',
            curType: 'goodsId', //当前类型(门店/商品/类别/品牌)
            title: '选择商品',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'o2oGoods' })
          }
          break
        case 'openShopDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'shopOptions',
            curType: 'shopId', //当前类型(会员/门店/优惠券)
            title: '选择门店',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'shop' })
          }
          break
        case 'dialogChange':
          let list = []
          let curTable = this.dialogOptions.curTable
          let curType = this.dialogOptions.curType

          if(curType != 'timeDate'){
            console.log(type, row, 'type, row');
            console.log(curTable, 'curTable');
            console.log(curType, 'curType');
            console.log(this.dialogOptions.formData.table.check, 'this.dialogOptions.formData.table.check');
            if (this.dialogOptions.formData.table.check.length <= 0) return
            list = this.dialogOptions.formData.table.check
            // list = [...list, ...this[curTable].list]
            // list = uniqWith(list, (x, y) => x[curType] == y[curType])
            if (curType == 'goodsId') {
              list = list.flatMap(item =>
                item.units.map(i => ({
                  ...item,
                  ...i,
                }))
              )
              list = [...list, ...this.goodsOptions.list]
              // list = uniqWith(list, (x, y) => x[curType] == y[curType])
              list = list.filter(e => e.goodsId)
              console.log(list,'list');
              // list = [this.dialogOptions.formData.table.check[0]]
            } else {
              list = [...list, ...this[curTable].list]
              list = uniqWith(list, (x, y) => x.shopId == y.shopId)
            }
          }else{
            this[curTable].echoList = this.dialogOptions.formData.DateTimeList //方便适用时间回显
            list = await getDateChangeDetail(
              this.dialogOptions.formData.DateTimeList  || []
            )
          }
          this[curTable].list = list
          break
        default:
          break
      }
    },
    async submitForm (isAdd) {
      
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.goodsOptions.list.length == 0) return this.$message.warning('请选择商品')
          let form = {
            billNo: this.goodsOptions.billNo,
            billId: this.goodsOptions.billId,
            billName: this.goodsOptions.billName,
            begTime: this.goodsOptions.datetime[0],
            endTime: this.goodsOptions.datetime[1],
            billRemark: this.goodsOptions.billRemark,
            isCanWeixinPay: this.goodsOptions.type.includes('微信支付')? true : false,
            isCanVipPay: this.goodsOptions.type.includes('会员卡支付')? true : false,
            isCanDistill: this.goodsOptions.delivery.includes('门店自提')? true : false,
            isCanDelivery: this.goodsOptions.delivery.includes('同城配送')? true : false,
            isCanExpress: this.goodsOptions.delivery.includes('快递配送')? true : false,
            shopRangeType: this.goodsOptions.shopRangeType,
            bargainFirstRate: this.goodsOptions.bargainFirstRate,
            bargainLimitHour: this.goodsOptions.bargainLimitHour,
            bargainPeopleNum: this.goodsOptions.bargainPeopleNum,
            bargainNoPeopleType: this.goodsOptions.bargainNoPeopleType,
            bargainRuleType: this.goodsOptions.bargainRuleType,
            bargainLimitTimes: this.goodsOptions.bargainLimitTimes,
            bargainDayHelpTimes: this.goodsOptions.bargainDayHelpTimes,
            o2omallMarketingBargainCaseGoodsAddReqList: this.goodsOptions.list,
          }
          if ([1, 2].includes(this.goodsOptions.shopRangeType)) {
            let sourceIndex = this.shopOptions.list.findIndex(item => !item.shopNo )
            if (sourceIndex != '-1' || this.shopOptions.list.length == 0) return this.$message.error('请选择门店')
            this.shopOptions.list = this.shopOptions.list.map((item) => (
                {
                    ...item,
                    isExcl: this.goodsOptions.shopRangeType == 1? true : false,
                }
            ))
            form.o2omallMarketingBargainCaseShopList = this.shopOptions.list
          }

          if (form.billId) {
            bargainCaseUpdate(form).then((res) => {
              this.$message.success('修改成功')
              this.loading = false
            }).catch((err) => {
              this.loading = false
            })
          } else {
            bargainCaseSave(form).then((res) => {
              this.$message.success('新增成功')
              this.goodsOptions.billId = res.data
              this.loading = false
              return true
            }).catch((err) => {
              this.loading = false
              return true
            })
          }
        } else {
          this.$message.warning('请输入必填项')
          return false
        }
      })
    },
    async auditForm (type) {
      let valid = await this.$refs['form'].validate()
      if (!valid) {
        return
      }
      if ([2, '2'].includes(this.goodsOptions.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        const { data } = await bargainCaseAudit({
          billIds: [this.goodsOptions.billId],
          auditStatus: type == '审核'? 2 : 0
        })
        this.goodsOptions.billStatus = 2
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  },
  activated () {
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  //附件
  .accessoryUpload {
    margin: 6px 10px;
    min-height: 150px;
    width: 200px;
    height: 200px;
  }
  .el-upload--picture-card{
    width: 200px;
    height: 200px;
  }
  ::v-deep .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }
}
.table {
  padding: 10px;
}
.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }
  .el-icon-question {
    margin-top: 10px;
  }
  ::v-deep .el-input--medium {
    width: 140px;
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
  //充值规则
.giveRule {
  padding: 0 20px;
  .multiplyTitle {
    padding: 20px 0 10px 0;
    .checkboxTitle {
      margin-left: 7px;
      font-size: 14px;
    }
  }
  //充值规则列表
  .fillRuleListTable {
    display: flex;
    cursor: pointer;
    .fillRuleList {
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #e6e6e6;
      .fillRuleListAdd {
        color: #2386ee;
        display: flex;
        flex-direction: row;
        line-height: 110px;
        align-items: center;
        justify-content: center;
      }
      //删除图标
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    //充值规则列表获得焦点
    .focusfillRuleList {
      position: relative;
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #017ee1;

      &::before {
        position: absolute;
        content: '';
        right: 0;
        bottom: 0;
        border-right: 13px solid #2386ee;
        border-left: 13px solid transparent;
        border-top: 11px solid transparent;
        border-bottom: 11px solid #2386ee;
      }
      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 6px;
        background-color: transparent;
        right: 3px;
        bottom: 5px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  //充值规则详情
  .RuleListdetail {
    display: flex;
    align-items: center;
    margin: 5px 0 20px 20px;
    height: 28px;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    //指定商品
    .appointGoods {
      height: 28px;
      line-height: 28px;
      margin-left: 20px;
    }
    //赠送金额多选框
    .giveMoneyRadio {
      margin: 0 20px;
    }
    //多选框
    .el-checkbox {
      margin-right: 0;
    }
    span {
      font-size: 14px;
    }
    //充值规则详情
    .fillRuleinput {
      width: 150px;
      margin-left: 20px;
    }
    //赠送金额
    .fillRuleinputMoney {
      width: 150px;
      margin-right: 10px;
      margin-left: 10px;
    }

    //详情标题
    .checkboxTitle {
      margin-left: 7px;
      margin-right: 5px;
    }
  }
}
.mt10 {
  margin-top: 10px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.mt20 {
  margin-bottom: 20px !important;
  // margin-button: 30px;
}
.mt0 {
  margin-bottom: 0px !important;
  // margin-button: 30px;
}
.mar20 {
  margin: 10px !important;
}
.elFormItem {
  .el-form-item{
    margin-bottom: 0 !important;
  }
}
.marT10 {
  margin-top: 10px;
}
.text {
  color: rgb(153, 153, 153);
  font-size: 12px;
}
</style>
